import Avatar from "@material-ui/core/Avatar"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import IconButton from "@material-ui/core/IconButton"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import DeleteIcon from "@material-ui/icons/Delete"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import React from "react"
import fontStyle from "src/entity/theme/fontStyle"
import swal from "sweetalert"

import swalErrorAction from "../../../entity/alert/swalErrorAction"
import { willDelete, networkError, deleteError } from "../../../entity/alert/swalErrorList"
import Theme from "../../../entity/theme/theme"
import { attendanceRepository } from "../../../repository/action"
import LinearProgress from "../../atoms/linearProgress"

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: "33.33%",
        flexShrink: 0,
        display: "flex",
        fontFamily: Theme.font.fontFamily,
    },
    grade: {
        fontSize: theme.typography.pxToRem(10),
        flexBasis: "33.33%",
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: theme.typography.pxToRem(15),
        color: theme.palette.text.secondary,
        fontFamily: Theme.font.fontFamily,
    },
}))

export default function WeekAttendanceList(props) {
    const {
        attendanceDay,
        expanded,
        allExpanded,
        setExpanded,
        distinction,
        day,
        loginUser,
        memberData,
        setAllAttendanceDay,
    } = props
    const classes = useStyles()
    const [deleteButtonFlag, setDeleteButtonFlag] = React.useState(false)
    const [linearAnimation, setLinearAnimation] = React.useState(Array(expanded.length).fill(false))

    const handleChange = (i) => {
        let flag = [...expanded]
        flag[i] = !flag[i]
        allExpanded[day] = flag
        setExpanded([...allExpanded])
    }

    const linearAnimationChange = (i) => {
        let flag = linearAnimation
        flag[i] = !flag[i]
        setLinearAnimation(flag)
    }

    return (
        <div className={classes.root}>
            {loginUser && attendanceDay && (
                <>
                    {attendanceDay.map((value, i) => {
                        // ////console.log(value)
                        let backColor
                        if (value.contributor_gender == "woman") {
                            backColor = Theme.color.womanIconColor
                        } else {
                            backColor = Theme.color.manIconColor
                        }
                        return (
                            <>
                                {distinction == "早退" && (
                                    <>
                                        {value.type !== "ob" && value.select == "早退" && (
                                            <>
                                                {linearAnimation[i] && (
                                                    <>
                                                        {expanded[i] ? (
                                                            <LinearProgress
                                                                margin={{ marginBottom: "-16px" }}
                                                            />
                                                        ) : (
                                                            <LinearProgress />
                                                        )}
                                                    </>
                                                )}
                                                <ExpansionPanel
                                                    key={`panel${i}`}
                                                    expanded={expanded[i]}
                                                    onChange={() => {
                                                        handleChange(i)
                                                    }}
                                                    TransitionProps={{ unmountOnExit: true }}
                                                    style={{ overflowX: "hidden" }}
                                                >
                                                    <ExpansionPanelSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                    >
                                                        <Typography className={classes.heading}>
                                                            <Avatar
                                                                style={{
                                                                    width: "33px",
                                                                    height: "33px",
                                                                    fontSize: "1em",
                                                                    fontWeight: "bold",
                                                                    background: backColor,
                                                                }}
                                                            >
                                                                {value.contributor.slice(0, 1)}
                                                            </Avatar>

                                                            <span
                                                                style={{
                                                                    wordBreak: "keep-all",
                                                                    margin: "auto 3px",
                                                                    fontSize: "12px",
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                {value.contributor}
                                                            </span>
                                                        </Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails
                                                        style={{ padding: "0px" }}
                                                    >
                                                        <Typography
                                                            style={{
                                                                textAlign: "left",
                                                                margin: "auto",
                                                                width: "80%",
                                                            }}
                                                        >
                                                            <span style={fontStyle("500")}>
                                                                <div
                                                                    style={{
                                                                        whiteSpace: "pre-line",
                                                                    }}
                                                                >
                                                                    {value.sentence}
                                                                </div>
                                                                <div style={{ margin: "14px 0px" }}>
                                                                    <div
                                                                        style={{
                                                                            textAlign: "right",
                                                                            fontSize: "13px",
                                                                        }}
                                                                    >
                                                                        送信日：{value.post_date}
                                                                    </div>
                                                                    {value.contributor ==
                                                                        loginUser.name && (
                                                                        <IconButton
                                                                            disabled={
                                                                                deleteButtonFlag
                                                                            }
                                                                            aria-label="delete"
                                                                            color="secondary"
                                                                            style={{
                                                                                fontSize: "11px",
                                                                                display: "flex",
                                                                                marginLeft: "auto",
                                                                            }}
                                                                            onClick={() => {
                                                                                swal(
                                                                                    willDelete
                                                                                ).then((val) => {
                                                                                    if (val) {
                                                                                        //////console.log(val);
                                                                                        linearAnimationChange(
                                                                                            i
                                                                                        )
                                                                                        setDeleteButtonFlag(
                                                                                            true
                                                                                        )
                                                                                        attendanceRepository
                                                                                            .attendanceDelete(
                                                                                                loginUser,
                                                                                                value
                                                                                            )
                                                                                            .then(
                                                                                                () => {
                                                                                                    return (
                                                                                                        swal(
                                                                                                            {
                                                                                                                title: "投稿を削除しました",
                                                                                                                icon: "success",
                                                                                                                buttons: false,
                                                                                                                timer: 1500,
                                                                                                            }
                                                                                                        ),
                                                                                                        attendanceRepository
                                                                                                            .attendanceDataFetch(
                                                                                                                loginUser,
                                                                                                                memberData
                                                                                                            )
                                                                                                            .then(
                                                                                                                (
                                                                                                                    data
                                                                                                                ) => {
                                                                                                                    setDeleteButtonFlag(
                                                                                                                        false
                                                                                                                    )
                                                                                                                    linearAnimationChange(
                                                                                                                        i
                                                                                                                    )
                                                                                                                    setAllAttendanceDay(
                                                                                                                        data
                                                                                                                    )
                                                                                                                }
                                                                                                            )
                                                                                                            .catch(
                                                                                                                () => {
                                                                                                                    swalErrorAction(
                                                                                                                        deleteError
                                                                                                                    )
                                                                                                                }
                                                                                                            )
                                                                                                    )
                                                                                                }
                                                                                            )
                                                                                            .catch(
                                                                                                () => {
                                                                                                    swalErrorAction(
                                                                                                        networkError
                                                                                                    )
                                                                                                }
                                                                                            )
                                                                                    }
                                                                                })
                                                                            }}
                                                                        >
                                                                            <DeleteIcon />
                                                                            削除
                                                                        </IconButton>
                                                                    )}
                                                                </div>
                                                            </span>
                                                        </Typography>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                            </>
                                        )}
                                    </>
                                )}
                                {distinction == "遅刻" && (
                                    <>
                                        {value.type !== "ob" && value.select == "遅刻" && (
                                            <>
                                                {linearAnimation[i] && (
                                                    <>
                                                        {expanded[i] ? (
                                                            <LinearProgress
                                                                margin={{ marginBottom: "-16px" }}
                                                            />
                                                        ) : (
                                                            <LinearProgress />
                                                        )}
                                                    </>
                                                )}
                                                <ExpansionPanel
                                                    key={`panel${i}`}
                                                    expanded={expanded[i]}
                                                    onChange={() => {
                                                        handleChange(i)
                                                    }}
                                                    TransitionProps={{ unmountOnExit: true }}
                                                    style={{ overflowX: "hidden" }}
                                                >
                                                    <ExpansionPanelSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        // style={{ padding: "0px 18px 0px 36px" }}
                                                    >
                                                        <Typography className={classes.heading}>
                                                            <Avatar
                                                                style={{
                                                                    width: "33px",
                                                                    height: "33px",
                                                                    fontSize: "1em",
                                                                    fontWeight: "bold",
                                                                    background: backColor,
                                                                }}
                                                            >
                                                                {value.contributor.slice(0, 1)}
                                                            </Avatar>

                                                            <span
                                                                style={{
                                                                    wordBreak: "keep-all",
                                                                    margin: "auto 3px",
                                                                    fontSize: "12px",
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                {value.contributor}
                                                            </span>
                                                        </Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails
                                                        style={{ padding: "0px" }}
                                                    >
                                                        <Typography
                                                            style={{
                                                                textAlign: "left",
                                                                margin: "auto",
                                                                width: "80%",
                                                            }}
                                                        >
                                                            <span style={fontStyle("500")}>
                                                                <div
                                                                    style={{
                                                                        whiteSpace: "pre-line",
                                                                    }}
                                                                >
                                                                    {value.sentence}
                                                                </div>
                                                                <div style={{ margin: "14px 0px" }}>
                                                                    <div
                                                                        style={{
                                                                            textAlign: "right",
                                                                            fontSize: "13px",
                                                                        }}
                                                                    >
                                                                        送信日：{value.post_date}
                                                                    </div>
                                                                    {value.contributor ==
                                                                        loginUser.name && (
                                                                        <IconButton
                                                                            disabled={
                                                                                deleteButtonFlag
                                                                            }
                                                                            aria-label="delete"
                                                                            color="secondary"
                                                                            style={{
                                                                                fontSize: "11px",
                                                                                display: "flex",
                                                                                marginLeft: "auto",
                                                                            }}
                                                                            onClick={() => {
                                                                                swal(
                                                                                    willDelete
                                                                                ).then((val) => {
                                                                                    if (val) {
                                                                                        //////console.log(val);
                                                                                        linearAnimationChange(
                                                                                            i
                                                                                        )
                                                                                        setDeleteButtonFlag(
                                                                                            true
                                                                                        )
                                                                                        attendanceRepository
                                                                                            .attendanceDelete(
                                                                                                loginUser,
                                                                                                value
                                                                                            )
                                                                                            .then(
                                                                                                () => {
                                                                                                    return (
                                                                                                        swal(
                                                                                                            {
                                                                                                                title: "投稿を削除しました",
                                                                                                                icon: "success",
                                                                                                                buttons: false,
                                                                                                                timer: 1500,
                                                                                                            }
                                                                                                        ),
                                                                                                        attendanceRepository
                                                                                                            .attendanceDataFetch(
                                                                                                                loginUser,
                                                                                                                memberData
                                                                                                            )
                                                                                                            .then(
                                                                                                                (
                                                                                                                    data
                                                                                                                ) => {
                                                                                                                    setDeleteButtonFlag(
                                                                                                                        false
                                                                                                                    )
                                                                                                                    linearAnimationChange(
                                                                                                                        i
                                                                                                                    )
                                                                                                                    setAllAttendanceDay(
                                                                                                                        data
                                                                                                                    )
                                                                                                                }
                                                                                                            )
                                                                                                            .catch(
                                                                                                                () => {
                                                                                                                    swalErrorAction(
                                                                                                                        deleteError
                                                                                                                    )
                                                                                                                }
                                                                                                            )
                                                                                                    )
                                                                                                }
                                                                                            )
                                                                                            .catch(
                                                                                                () => {
                                                                                                    swalErrorAction(
                                                                                                        networkError
                                                                                                    )
                                                                                                }
                                                                                            )
                                                                                    }
                                                                                })
                                                                            }}
                                                                        >
                                                                            <DeleteIcon />
                                                                            削除
                                                                        </IconButton>
                                                                    )}
                                                                </div>
                                                            </span>
                                                        </Typography>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                            </>
                                        )}
                                    </>
                                )}
                                {distinction == "欠席" && (
                                    <>
                                        {value.type !== "ob" && value.select == "欠席" && (
                                            <>
                                                {linearAnimation[i] && (
                                                    <>
                                                        {expanded[i] ? (
                                                            <LinearProgress
                                                                margin={{ marginBottom: "-16px" }}
                                                            />
                                                        ) : (
                                                            <LinearProgress />
                                                        )}
                                                    </>
                                                )}
                                                <ExpansionPanel
                                                    key={`panel${i}`}
                                                    expanded={expanded[i]}
                                                    onChange={() => {
                                                        handleChange(i)
                                                    }}
                                                    TransitionProps={{ unmountOnExit: true }}
                                                    style={{ overflowX: "hidden" }}
                                                >
                                                    <ExpansionPanelSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel1a-content"
                                                        // style={{ padding: "0px 18px 0px 36px" }}
                                                    >
                                                        <Typography className={classes.heading}>
                                                            <Avatar
                                                                style={{
                                                                    width: "33px",
                                                                    height: "33px",
                                                                    fontSize: "1em",
                                                                    fontWeight: "bold",
                                                                    background: backColor,
                                                                }}
                                                            >
                                                                {value.contributor.slice(0, 1)}
                                                            </Avatar>

                                                            <span
                                                                style={{
                                                                    wordBreak: "keep-all",
                                                                    margin: "auto 3px",
                                                                    fontSize: "12px",
                                                                    fontWeight: "bold",
                                                                }}
                                                            >
                                                                {value.contributor}
                                                            </span>
                                                        </Typography>
                                                    </ExpansionPanelSummary>
                                                    <ExpansionPanelDetails
                                                        style={{ padding: "0px" }}
                                                    >
                                                        <Typography
                                                            style={{
                                                                textAlign: "left",
                                                                margin: "auto",
                                                                width: "80%",
                                                            }}
                                                        >
                                                            <span style={fontStyle("500")}>
                                                                <div
                                                                    style={{
                                                                        whiteSpace: "pre-line",
                                                                    }}
                                                                >
                                                                    {value.sentence}
                                                                </div>
                                                                <div style={{ margin: "14px 0px" }}>
                                                                    <div
                                                                        style={{
                                                                            textAlign: "right",
                                                                            fontSize: "13px",
                                                                        }}
                                                                    >
                                                                        送信日：{value.post_date}
                                                                    </div>
                                                                    {value.contributor ==
                                                                        loginUser.name && (
                                                                        <IconButton
                                                                            disabled={
                                                                                deleteButtonFlag
                                                                            }
                                                                            aria-label="delete"
                                                                            color="secondary"
                                                                            style={{
                                                                                fontSize: "11px",
                                                                                display: "flex",
                                                                                marginLeft: "auto",
                                                                            }}
                                                                            onClick={() => {
                                                                                swal(
                                                                                    willDelete
                                                                                ).then((val) => {
                                                                                    if (val) {
                                                                                        linearAnimationChange(
                                                                                            i
                                                                                        )
                                                                                        setDeleteButtonFlag(
                                                                                            true
                                                                                        )
                                                                                        attendanceRepository
                                                                                            .attendanceDelete(
                                                                                                loginUser,
                                                                                                value
                                                                                            )
                                                                                            .then(
                                                                                                () => {
                                                                                                    return (
                                                                                                        swal(
                                                                                                            {
                                                                                                                title: "投稿を削除しました",
                                                                                                                icon: "success",
                                                                                                                buttons: false,
                                                                                                                timer: 1500,
                                                                                                            }
                                                                                                        ),
                                                                                                        attendanceRepository
                                                                                                            .attendanceDataFetch(
                                                                                                                loginUser,
                                                                                                                memberData
                                                                                                            )
                                                                                                            .then(
                                                                                                                (
                                                                                                                    data
                                                                                                                ) => {
                                                                                                                    setDeleteButtonFlag(
                                                                                                                        false
                                                                                                                    )
                                                                                                                    linearAnimationChange(
                                                                                                                        i
                                                                                                                    )
                                                                                                                    setAllAttendanceDay(
                                                                                                                        data
                                                                                                                    )
                                                                                                                }
                                                                                                            )
                                                                                                            .catch(
                                                                                                                () => {
                                                                                                                    swalErrorAction(
                                                                                                                        deleteError
                                                                                                                    )
                                                                                                                }
                                                                                                            )
                                                                                                    )
                                                                                                }
                                                                                            )
                                                                                            .catch(
                                                                                                () => {
                                                                                                    swalErrorAction(
                                                                                                        networkError
                                                                                                    )
                                                                                                }
                                                                                            )
                                                                                    }
                                                                                })
                                                                            }}
                                                                        >
                                                                            <DeleteIcon />
                                                                            削除
                                                                        </IconButton>
                                                                    )}
                                                                </div>
                                                            </span>
                                                        </Typography>
                                                    </ExpansionPanelDetails>
                                                </ExpansionPanel>
                                            </>
                                        )}
                                    </>
                                )}
                            </>
                        )
                    })}
                </>
            )}
        </div>
    )
}
