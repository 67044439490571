import Avatar from "@material-ui/core/Avatar"
import ExpansionPanel from "@material-ui/core/ExpansionPanel"
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails"
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import React from "react"

import Theme from "../../../entity/theme/theme"

import AttendanceCalendar from "./attendanceCalendar"

const useStyles = makeStyles((theme) => ({
    root: {
        // background: "#fff",
        width: "100%",
        // paddingBottom: "20%"
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        flexBasis: "33.33%",
        flexShrink: 0,
        display: "flex",
        fontFamily: Theme.font.fontFamily,
        fontWeight: "bold",
    },
    grade: {
        fontSize: theme.typography.pxToRem(10),
        flexBasis: "33.33%",
        flexShrink: 0,
    },
    secondaryHeading: {
        fontSize: "0.8775rem",
        color: theme.palette.text.secondary,
        fontFamily: Theme.font.fontFamily,
        fontWeight: "bold",
    },
}))

export default function PastAttendanceList(props) {
    const { pastAttendanceList, sort, rangeDate } = props
    const classes = useStyles()
    const [expanded, setExpanded] = React.useState()

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    return (
        <div className={classes.root}>
            <>
                {pastAttendanceList.map((value, i) => {
                    // ////console.log(value)
                    let backColor
                    if (value.gender == "woman") {
                        backColor = Theme.color.womanIconColor
                    } else {
                        backColor = Theme.color.manIconColor
                    }
                    return (
                        <>
                            <ExpansionPanel
                                key={`panel${i}`}
                                expanded={expanded === `panel${i}`}
                                onChange={handleChange(`panel${i}`)}
                                TransitionProps={{ unmountOnExit: true }}
                                style={{ overflowX: "hidden" }}
                            >
                                <ExpansionPanelSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                >
                                    <Typography className={classes.heading}>
                                        <Avatar
                                            style={{
                                                width: "33px",
                                                height: "33px",
                                                fontSize: "1em",
                                                margin: "auto 0px",
                                                background: backColor,
                                            }}
                                        >
                                            {value.name.slice(0, 1)}
                                        </Avatar>
                                        <span
                                            style={{
                                                wordBreak: "break-word",
                                                margin: "auto 3px",
                                                fontSize: "12px",
                                                fontWeight: "bold",
                                            }}
                                        >
                                            {value.name}
                                        </span>
                                    </Typography>
                                    <Typography className={classes.secondaryHeading}>
                                        <span>
                                            {value.leaveEarly > -1 && (
                                                <>
                                                    早退：
                                                    {sort == "leaveEarly" ? (
                                                        <span style={{ color: "blue" }}>
                                                            {value.leaveEarly} 回｜
                                                        </span>
                                                    ) : (
                                                        <>{value.leaveEarly}回｜</>
                                                    )}
                                                </>
                                            )}
                                        </span>
                                        <span>
                                            {value.late > -1 && (
                                                <>
                                                    遅刻：
                                                    {sort == "late" ? (
                                                        <span style={{ color: "blue" }}>
                                                            {value.late}回
                                                        </span>
                                                    ) : (
                                                        <>{value.late}回</>
                                                    )}
                                                </>
                                            )}
                                        </span>
                                        <div>
                                            {value.absence > -1 && (
                                                <>
                                                    欠席：
                                                    {sort == "absence" ? (
                                                        <span style={{ color: "blue" }}>
                                                            {value.absence}回
                                                        </span>
                                                    ) : (
                                                        <>{value.absence}回</>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    </Typography>
                                </ExpansionPanelSummary>
                                <ExpansionPanelDetails style={{ padding: "0px 0px 20px 0px" }}>
                                    {value.attendance && value.attendance.length > 0 ? (
                                        <div style={{ width: "100%" }}>
                                            <Paper>
                                                <AttendanceCalendar
                                                    attendance={value.attendance}
                                                    rangeDate={rangeDate}
                                                />
                                            </Paper>
                                        </div>
                                    ) : (
                                        <div style={{ textAlign: "left", margin: "auto" }}>
                                            データがありません
                                        </div>
                                    )}
                                </ExpansionPanelDetails>
                            </ExpansionPanel>
                        </>
                    )
                })}
            </>
        </div>
    )
}
