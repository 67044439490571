import Backdrop from "@material-ui/core/Backdrop"
import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Fade from "@material-ui/core/Fade"
import GridList from "@material-ui/core/GridList"
import GridListTile from "@material-ui/core/GridListTile"
import Popper from "@material-ui/core/Popper"
import Typography from "@material-ui/core/Typography"
import { makeStyles } from "@material-ui/core/styles"
import moment from "moment"
import React from "react"
import fontStyle from "src/entity/theme/fontStyle"

import Theme from "../../../entity/theme/theme"

const weekdays = ["日", "月", "火", "水", "木", "金", "土"]

const useStyles = makeStyles((theme) => ({
    root: {
        padding: "8px 4px 0px",
        textAlign: "end",
        fontFamily: Theme.font.fontFamily,
    },
    cardStyle: {
        margin: "-10px",
        textAlign: "end",
    },
    typography: {
        padding: theme.spacing(2),
        fontFamily: Theme.font.fontFamily,
        fontWeight: "bold",
    },
    attendanceSentenceFont: {
        fontFamily: Theme.font.fontFamily,
        fontWeight: "500",
        fontSize: "16px",
    },
}))

export default function AttendanceCalendar(props) {
    const { attendance, rangeDate } = props
    const begining = new Date(
        rangeDate.startDate.format("YYYY"),
        Number(rangeDate.startDate.format("M")) - 1,
        1
    )
    const calendar = []
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = React.useState(null)
    const [open, setOpen] = React.useState(false)
    const [attendanceSentence, setAttendanceSentence] = React.useState({})
    const [placement, setPlacement] = React.useState()

    let sortWill = [...attendance]
    let sortAttendance = sortWill.sort(compare)
    //const categoryColor = ["forestgreen", "cornflowerblue", "lightcoral"]

    const compare = (a, b) => {
        const dateA = moment(a.date).format("X")
        const dateB = moment(b.date).format("X")
        //////////console.log(dateA, dateB);
        let comparison = 0
        if (dateA >= dateB) {
            comparison = 1
        } else if (dateA < dateB) {
            comparison = -1
        }
        return comparison
    }

    const handleClick = (newPlacement, index) => (event) => {
        if (sortAttendance[index]) {
            //console.log(sortAttendance[index])
            setAttendanceSentence(sortAttendance[index])
            setAnchorEl(event.currentTarget)
            setOpen((prev) => placement !== newPlacement || !prev)
            setPlacement(newPlacement)
        }
    }

    const handleClose = () => {
        setAttendanceSentence({})
        setAnchorEl(null)
        setOpen(false)
    }

    //カレンダータイトル
    Array.apply(null, { length: begining.getDay() })
        .map(Number.call, Number)
        .forEach(() => {
            calendar.push(
                <GridListTile>
                    {" "}
                    <Card style={{ textAlign: "end" }} />
                </GridListTile>
            )
        })
    //カレンダーの中身
    Array.apply(null, { length: 32 })
        .map(Number.call, Number)
        .forEach((i) => {
            let index
            const day = new Date(begining.getFullYear(), begining.getMonth(), 1 + i)
            sortAttendance.forEach((value, ind) => {
                if (value.date == day.toLocaleDateString()) {
                    index = ind
                }
            })
            if (day.getMonth() === begining.getMonth()) {
                calendar.push(
                    <GridListTile>
                        <Card className={classes.cardStyle}>
                            <Button style={{ padding: "6px 16px" }}>
                                <CardContent
                                    className={classes.root}
                                    style={{ paddingBottom: "0px" }}
                                    onClick={handleClick("bottom-start", index)}
                                >
                                    {index > -1 &&
                                    sortAttendance.some((value) => {
                                        return value.date == day.toLocaleDateString()
                                    }) ? (
                                        <Typography style={{ paddingLeft: "12px" }}>
                                            {day.toLocaleDateString().split("/")[2]}
                                        </Typography>
                                    ) : (
                                        <Typography>
                                            {day.toLocaleDateString().split("/")[2]}
                                        </Typography>
                                    )}
                                    {index > -1 &&
                                    sortAttendance.some((value) => {
                                        return value.date == day.toLocaleDateString()
                                    }) ? (
                                        <Typography style={{ paddingBottom: "4px" }}>
                                            <span style={{ color: "forestgreen" }}>
                                                {sortAttendance[index].select == "早退" && "早"}
                                            </span>
                                            <span style={{ color: "steelblue" }}>
                                                {" "}
                                                {sortAttendance[index].select == "遅刻" && "遅"}
                                            </span>
                                            <span style={{ color: "lightcoral" }}>
                                                {sortAttendance[index].select == "欠席" && "欠"}
                                            </span>
                                        </Typography>
                                    ) : (
                                        <Typography
                                            style={{ paddingBottom: "28px", paddingLeft: "28px" }}
                                        />
                                    )}
                                </CardContent>
                            </Button>
                        </Card>
                    </GridListTile>
                )
            }
        })

    ////console.log({ rangeDate, attendance })

    return (
        <>
            <GridList cols={7} cellHeight="auto">
                {weekdays.map((w) => {
                    const styles = {}
                    if (w == "日") {
                        styles.color = "red"
                        styles.marginRight = "15px"
                        styles.marginTop = "15px"
                    }
                    if (w == "土") {
                        styles.color = Theme.color.primaryColor
                        styles.marginRight = "15px"
                        styles.marginTop = "15px"
                    }
                    if (w == "月") {
                        styles.marginRight = "15px"
                        styles.marginTop = "15px"
                    }
                    if (w == "火") {
                        styles.marginRight = "15px"
                        styles.marginTop = "15px"
                    }
                    if (w == "水") {
                        styles.marginRight = "15px"
                        styles.marginTop = "15px"
                    }
                    if (w == "木") {
                        styles.marginRight = "15px"
                        styles.marginTop = "15px"
                    }
                    if (w == "金") {
                        styles.marginRight = "15px"
                        styles.marginTop = "15px"
                    }
                    return (
                        <GridListTile key={w}>
                            <Card className={classes.cardStyle}>
                                <CardContent className={classes.root}>
                                    <Typography style={styles}>
                                        <span style={fontStyle("600")}>{w}</span>
                                    </Typography>
                                </CardContent>
                            </Card>
                        </GridListTile>
                    )
                })}
                {calendar}
            </GridList>
            <Backdrop open={open} onClick={handleClose} style={{ zIndex: 8, opacity: "-1" }} />
            <Popper
                open={open}
                anchorEl={anchorEl}
                placement={placement}
                transition
                style={{ zIndex: 9, width: "220px" }}
            >
                {({ TransitionProps }) => (
                    <Fade {...TransitionProps} timeout={350}>
                        <Card className={classes.typography}>
                            <div className={classes.attendanceSentenceFont}>
                                <div style={{ whiteSpace: "pre-line" }}>
                                    {attendanceSentence.sentence}
                                </div>
                                <div style={{ margin: "8px 0px 0px" }}>
                                    <div style={{ textAlign: "right", fontSize: "11px" }}>
                                        {" "}
                                        送信日:{attendanceSentence.post_date}
                                    </div>
                                </div>
                            </div>
                        </Card>
                    </Fade>
                )}
            </Popper>
        </>
    )
}
