import FormControl from "@material-ui/core/FormControl"
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import { makeStyles } from "@material-ui/core/styles"
import ArrowDownwardIcon from "@material-ui/icons/ArrowDownward"
import React from "react"

import Theme from "../../../entity/theme/theme"

const useStyles = makeStyles(() => ({
    formControl: {
        width: "100px",
        fontFamily: Theme.font.fontFamily,
        fontWeight: "600",
    },
    menuItem: {
        display: "flex",
        alignItems: "center",
        fontFamily: Theme.font.fontFamily,
        fontSize: "13px",
    },
}))

const AttendanceSort = React.memo((props) => {
    const classes = useStyles()
    const { sort, handleSortChange } = props

    return (
        <FormControl className={classes.formControl}>
            <Select
                labelId="demo-simple-select-outlined-label"
                id="demo-simple-select-outlined"
                value={sort}
                onChange={handleSortChange}
                label="sort"
            >
                <MenuItem value={"absence"}>
                    <div className={classes.menuItem}>
                        欠席
                        <ArrowDownwardIcon color="action" fontSize="small" />
                    </div>
                </MenuItem>
                <MenuItem value={"late"}>
                    <div className={classes.menuItem}>
                        遅刻
                        <ArrowDownwardIcon color="action" fontSize="small" />
                    </div>
                </MenuItem>
                <MenuItem value={"leaveEarly"}>
                    <div className={classes.menuItem}>
                        早退
                        <ArrowDownwardIcon color="action" fontSize="small" />
                    </div>
                </MenuItem>
            </Select>
        </FormControl>
    )
})

export default AttendanceSort
