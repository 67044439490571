import Button from "@material-ui/core/Button"
import Card from "@material-ui/core/Card"
import { makeStyles } from "@material-ui/core/styles"
import AirlineSeatIndividualSuiteIcon from "@material-ui/icons/AirlineSeatIndividualSuite"
import DirectionsBikeIcon from "@material-ui/icons/DirectionsBike"
import DirectionsRunIcon from "@material-ui/icons/DirectionsRun"
import React from "react"
import fontStyle from "src/entity/theme/fontStyle"

import Theme from "../../../entity/theme/theme"

import WeekAttendanceList from "./weekAttendanceList"

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: "#fff",
        fontFamily: Theme.font.fontFamily,
    },
    allButton: {
        textAlign: "left",
        fontWeight: "bold",
        borderRadius: "0px",
        color: "white",
        fontFamily: Theme.font.fontFamily,
    },
    buttonStyle: {
        width: "100%",
        height: "100%",
        margin: "auto",
        textAlign: "center",
    },
    day: {
        background: "aliceblue",
        fontSize: "26px",
        fontWeight: "bold",
        fontFamily: Theme.font.fontFamily,
        padding: "8px",
    },
    margin: {
        margin: "25% 0%",
    },
    personNum: {
        fontFamily: Theme.font.fontFamily,
        margin: "4px",
    },
    threeButtonStyle: {
        fontFamily: Theme.font.fontFamily,
        fontWeight: "bold",
        display: "flex",
        alignItems: "center",
    },
}))

export default function WeekAttendance(props) {
    const {
        loginUser,
        memberData,
        attendanceDay,
        setAllAttendanceDay,
        expanded,
        setExpanded,
        distribution,
    } = props
    const classes = useStyles()

    const allOpen = (i) => {
        let expand = expanded
        let flag = Array(expand[i].length).fill(true)
        expand[i] = flag
        setExpanded([...expand])
    }
    const allClose = (i) => {
        let expand = expanded
        let flag = Array(expand[i].length).fill(false)
        expand[i] = flag
        setExpanded([...expand])
    }

    return (
        <div className={classes.root}>
            <div style={{ background: "#fff", paddingBottom: "100px" }}>
                <div style={{ margin: "10px" }}>
                    <div style={fontStyle("bold")}>
                        <div>今日から一週間の遅刻、欠席者だけ表示しています</div>
                        <div>ex)4月1日(今日)～4月7日表示、4月15日は非表示</div>
                    </div>
                </div>

                {expanded && expanded.length > 0 && attendanceDay && (
                    <>
                        {attendanceDay.map((value, i) => {
                            let day = new Date()
                            day.setDate(day.getDate() + i)
                            let dayOfWeekStr = ["日", "月", "火", "水", "木", "金", "土"][
                                day.getDay()
                            ]
                            let weekDay = day.toLocaleDateString()

                            return (
                                <>
                                    {value.length >= 0 && (
                                        <>
                                            <Card style={{ margin: "10px" }} key={`attendance${i}`}>
                                                <div className={classes.day}>
                                                    {weekDay.split("/")[1] +
                                                        "月" +
                                                        weekDay.split("/")[2] +
                                                        "日"}
                                                    {"(" + dayOfWeekStr + ")"}
                                                </div>
                                                <div className={classes.threeButtonStyle}>
                                                    <Button
                                                        className={classes.allButton}
                                                        variant="contained"
                                                        style={{ backgroundColor: "forestgreen" }}
                                                        startIcon={
                                                            <DirectionsBikeIcon
                                                                style={{ fontSize: "x-large" }}
                                                            />
                                                        }
                                                    >
                                                        <div
                                                            style={{
                                                                fontSize: "15px",
                                                                fontWeight: "bold",
                                                            }}
                                                        >
                                                            早退
                                                        </div>
                                                    </Button>
                                                    {(() => {
                                                        let count = 0
                                                        value.forEach((v) => {
                                                            if (v.select == "早退") count++
                                                        })
                                                        return (
                                                            <span className={classes.personNum}>
                                                                {count}人
                                                            </span>
                                                        )
                                                    })()}
                                                    <div style={{ marginLeft: "auto" }}>
                                                        <Button
                                                            style={{
                                                                color: Theme.color.primaryColor,
                                                            }}
                                                            onClick={() => {
                                                                expanded[i].findIndex((v) => v) !==
                                                                -1
                                                                    ? allClose(i)
                                                                    : allOpen(i)
                                                            }}
                                                        >
                                                            <span style={fontStyle("bold")}>
                                                                {expanded[i].findIndex((v) => v) !==
                                                                -1
                                                                    ? "全て閉じる"
                                                                    : "全て開く"}
                                                            </span>
                                                        </Button>
                                                    </div>
                                                </div>
                                                <WeekAttendanceList
                                                    attendanceDay={value}
                                                    expanded={expanded[i]}
                                                    allExpanded={expanded}
                                                    day={i}
                                                    setExpanded={setExpanded}
                                                    distinction={"早退"}
                                                    loginUser={loginUser}
                                                    memberData={memberData}
                                                    setAllAttendanceDay={setAllAttendanceDay}
                                                    distribution={distribution}
                                                />
                                                <div style={fontStyle("bold")}>
                                                    <Button
                                                        className={classes.allButton}
                                                        variant="contained"
                                                        style={{ backgroundColor: "steelblue" }}
                                                        startIcon={
                                                            <DirectionsRunIcon
                                                                style={{ fontSize: "x-large" }}
                                                            />
                                                        }
                                                    >
                                                        <div
                                                            style={{
                                                                fontSize: "15px",
                                                                fontWeight: "bold",
                                                            }}
                                                        >
                                                            遅刻
                                                        </div>
                                                    </Button>
                                                    {(() => {
                                                        let count = 0
                                                        value.forEach((v) => {
                                                            if (v.select == "遅刻") count++
                                                        })
                                                        return (
                                                            <span className={classes.personNum}>
                                                                {count}人
                                                            </span>
                                                        )
                                                    })()}
                                                </div>
                                                <WeekAttendanceList
                                                    attendanceDay={value}
                                                    expanded={expanded[i]}
                                                    allExpanded={expanded}
                                                    day={i}
                                                    setExpanded={setExpanded}
                                                    distinction={"遅刻"}
                                                    loginUser={loginUser}
                                                    memberData={memberData}
                                                    setAllAttendanceDay={setAllAttendanceDay}
                                                    distribution={distribution}
                                                />
                                                <div style={fontStyle("bold")}>
                                                    <Button
                                                        className={classes.allButton}
                                                        variant="contained"
                                                        style={{ backgroundColor: "lightcoral" }}
                                                        startIcon={
                                                            <AirlineSeatIndividualSuiteIcon
                                                                style={{ fontSize: "x-large" }}
                                                            />
                                                        }
                                                        onClick={() => {}}
                                                    >
                                                        <div
                                                            style={{
                                                                fontSize: "15px",
                                                                fontWeight: "bold",
                                                            }}
                                                        >
                                                            欠席
                                                        </div>
                                                    </Button>
                                                    {(() => {
                                                        let count = 0
                                                        value.forEach((v) => {
                                                            if (v.select == "欠席") count++
                                                        })
                                                        return (
                                                            <span className={classes.personNum}>
                                                                {count}人
                                                            </span>
                                                        )
                                                    })()}
                                                </div>
                                                <WeekAttendanceList
                                                    attendanceDay={value}
                                                    expanded={expanded[i]}
                                                    allExpanded={expanded}
                                                    day={i}
                                                    setExpanded={setExpanded}
                                                    distinction={"欠席"}
                                                    loginUser={loginUser}
                                                    memberData={memberData}
                                                    setAllAttendanceDay={setAllAttendanceDay}
                                                    distribution={distribution}
                                                />
                                            </Card>
                                        </>
                                    )}
                                </>
                            )
                        })}
                    </>
                )}
            </div>
        </div>
    )
}
