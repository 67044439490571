import Tab from "@material-ui/core/Tab"
import Tabs from "@material-ui/core/Tabs"
import { makeStyles } from "@material-ui/core/styles"
import { navigate } from "gatsby"
import moment from "moment"
import React from "react"
import styled from "styled-components"

import swalErrorAction from "../entity/alert/swalErrorAction"
import { networkError } from "../entity/alert/swalErrorList"
import Theme from "../entity/theme/theme"
import BoxLoading from "../presentation/atoms/boxLoading"
import GenderCheckBox from "../presentation/molecules/genderCheckBox"
import SelectPicker from "../presentation/molecules/selectPicker"
import AttendanceSort from "../presentation/organisms/attendance/attendanceSort"
import PastAttendanceList from "../presentation/organisms/attendance/pastAttendanceList"
import WeekAttendance from "../presentation/organisms/attendance/weekAttendance"
import { attendanceRepository, userRepository } from "../repository/action"

import SelectPickerButtons from "@/presentation/molecules/selectPickerButtons"
import SortDisplaySwitch from "@/presentation/molecules/sortDisplaySwitch"
import FrameTemplate from "@/presentation/templates/frameTemplate"

const useStyles = makeStyles(() => ({
    root: {
        backgroundColor: "#fff",
        fontFamily: Theme.font.fontFamily,
        overflowY: "auto",
        overflowX: "hidden",
    },
    allButton: {
        textAlign: "left",
        marginRight: "auto",
        fontWeight: "bold",
        borderRadius: "0px",
        color: "white",
        fontFamily: Theme.font.fontFamily,
    },
    buttonStyle: {
        width: "100%",
        height: "100%",
        margin: "auto",
        textAlign: "center",
    },
    day: {
        background: "aliceblue",
        fontSize: "26px",
        fontWeight: "bold",
        fontFamily: Theme.font.fontFamily,
        padding: "8px",
        //color: "#fff",
        // textAlign:"center"
    },
    margin: {
        margin: "25% 0%",
    },
    personNum: {
        fontFamily: Theme.font.fontFamily,
        margin: "4px",
    },
    font: {
        fontFamily: Theme.font.fontFamily,
        fontWeight: "bold",
    },
    settings: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "flex-end",
        alignItems: "flex-end",
    },
    picker: {
        margin: "14px 0px",
    },
    pickerBreadcrumbs: {
        marginTop: "8px",
        marginLeft: "8px",
    },
    sortDisplaySwitch: {
        margin: "8px 0px 0px auto",
    },
    selectPickerButtonsSwitch: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "8px",
        marginLeft: "8px",
    },
}))

export default function Attendance(props) {
    const classes = useStyles()
    const [isLoading, setIsLoading] = React.useState(false)
    const [loginUser, setLoginUser] = React.useState()
    const [memberData, setMemberData] = React.useState()
    const [rangeDate, setRangeDate] = React.useState({
        startDate: moment().startOf("month"),
        endDate: moment().endOf("month"),
    })
    const [title, setTitle] = React.useState("出欠")
    const [expanded, setExpanded] = React.useState()
    const [allAttendanceDay, setAllAttendanceDay] = React.useState([])

    const [attendanceDay, setAttendanceDay] = React.useState([])
    const [pastAttendanceList, setPastAttendanceList] = React.useState([])

    const [notificationCount, setNotificationCount] = React.useState(0)
    const [distinction, setDistinction] = React.useState()
    const [selectedPicker, setSelectedPicker] = React.useState("month")
    const [isSortDisplay, setIsSortDisplay] = React.useState(false)
    const [linearAnimation, setLinearAnimation] = React.useState()
    const [sort, setSort] = React.useState("absence")
    const [checked, setChecked] = React.useState({
        man: true,
        woman: true,
    })

    const genderCheckBoxClick = (name) => (event) => {
        setChecked({
            ...checked,
            [name]: event.target.checked,
        })
    }
    const handleSortChange = (event) => {
        setSort(event.target.value)
    }

    function compare(a, b) {
        const genreA = a[sort]
        const genreB = b[sort]

        let comparison = 0
        if (genreA > genreB) {
            comparison = 1
        } else if (genreA < genreB) {
            comparison = -1
        }
        return comparison
    }

    const handleChange = (event, value) => {
        setDistinction(value)
        ////console.log(value);
    }

    const distribution = (attendance) => {
        let weekArray = []
        let result = []
        let day = new Date()
        day.setDate(day.getDate())
        let weekDay = day.toLocaleDateString()
        for (let count = 1; count <= 7; count++) {
            let dayArray = attendance.map((value) => {
                let obj = []
                value.forEach((v) => {
                    if (v && v.date == weekDay) {
                        obj = [...obj, v]
                    }
                })
                return obj
            })
            day = new Date()
            day.setDate(day.getDate() + Number(count))
            weekDay = day.toLocaleDateString()
            let correctDayArray = dayArray.filter((value) => {
                if (value.length > 0) {
                    return value
                }
            }) //undefind削除
            if (correctDayArray.length > 0) {
                result = correctDayArray.reduce((acc, c) => {
                    return [...acc, ...c]
                })
            }
            weekArray = [...weekArray, result]
            result = []
        }

        return weekArray
    }

    React.useEffect(() => {
        if (props.location.action) {
            if (props.location.state.loginUser) {
                setLoginUser(props.location.state.loginUser)
                let setUserjson = JSON.stringify(props.location.state.loginUser)
                localStorage.setItem(["loginUser"], [setUserjson])
            }
            if (props.location.state.memberData) {
                setMemberData(props.location.state.memberData)
            }
            setDistinction(0)
            if (props.location.state.attendance) {
                setAllAttendanceDay(props.location.state.attendance)
            }
        } else {
            let getUserjson = localStorage.getItem(["loginUser"])
            let getDistinctionjson = localStorage.getItem(["distinction"])
            if (getUserjson) {
                userRepository
                    .sessionLogin(JSON.parse(getUserjson).email, JSON.parse(getUserjson).password)
                    .then((data) => {
                        setLoginUser(data.user_info)
                        setMemberData(data.memberData)
                        attendanceRepository
                            .attendanceDataFetch(JSON.parse(getUserjson), data.memberData)
                            .then((data) => {
                                setAllAttendanceDay(data)
                                setDistinction(JSON.parse(getDistinctionjson))
                            })
                            .catch(() => {
                                swalErrorAction(networkError)
                            })
                    })
                    .catch(() => {
                        swalErrorAction(networkError)
                    })
            } else {
                navigate("/")
            }
        }
    }, [])

    React.useEffect(() => {
        if (memberData) {
            let notification_count = 0
            memberData.forEach((v) => {
                if (v.permission == "1") {
                    notification_count++
                }
            })
            setNotificationCount(notification_count)
        }
    }, [memberData])

    React.useEffect(() => {
        let attendanceArray
        let expand
        switch (Number(distinction)) {
            case 0:
                //setLinearAnimation(Array(expanded.length).fill(false));
                attendanceArray = distribution([...allAttendanceDay])
                setAttendanceDay(attendanceArray)
                expand = [
                    Array(attendanceArray[0].length).fill(false),
                    Array(attendanceArray[1].length).fill(false),
                    Array(attendanceArray[2].length).fill(false),
                    Array(attendanceArray[3].length).fill(false),
                    Array(attendanceArray[4].length).fill(false),
                    Array(attendanceArray[5].length).fill(false),
                    Array(attendanceArray[6].length).fill(false),
                ]
                setLinearAnimation(Array(expand.length).fill(false))
                setExpanded(expand)
                setTitle("出欠 予定")
                localStorage.setItem(["distinction"], 0)
                break
            case 1: {
                attendanceArray = [...allAttendanceDay].map((v) => {
                    return v.filter((value) => {
                        let date = moment(value.date).format("X")
                        if (
                            rangeDate.startDate.format("X") <= date &&
                            date <= rangeDate.endDate.format("X") &&
                            date < moment(moment().startOf("day")).format("X")
                        ) {
                            return value
                        }
                    })
                })

                let allowMemberData = memberData.filter((value) => {
                    if (value.type !== "ob" && value.permission === "2") {
                        return value
                    }
                })

                let result = attendanceArray.map((value, index) => {
                    let attendance = {
                        leaveEarly: 0,
                        late: 0,
                        absence: 0,
                        id: allowMemberData[index].id,
                        name: allowMemberData[index].name,
                        type: allowMemberData[index].type,
                        gender: allowMemberData[index].gender,
                        team: allowMemberData[index].team,
                        create_date: allowMemberData[index].create_date,
                        permission: allowMemberData[index].permission,
                        display: allowMemberData[index].display,
                        attendance: value,
                    }
                    for (let i = 0; i < value.length; i++) {
                        switch (value[i].select) {
                            case "早退":
                                attendance.leaveEarly = attendance.leaveEarly + 1
                                break
                            case "遅刻":
                                attendance.late = attendance.late + 1
                                break
                            case "欠席":
                                attendance.absence = attendance.absence + 1
                                break
                        }
                    }
                    return attendance
                })
                let filterResult = result.filter((v) => v)
                let rateFilter = [...filterResult].filter((element) => {
                    let elementChecked
                    if (element.gender === "man") {
                        elementChecked = checked.man
                    } else if (element.gender === "woman") {
                        elementChecked = checked.woman
                    }
                    return elementChecked
                })
                rateFilter = rateFilter.sort(compare)
                setPastAttendanceList(rateFilter)
                setTitle("出欠 過去")
                localStorage.setItem(["distinction"], 1)
                break
            }
        }
    }, [allAttendanceDay, distinction, rangeDate, sort, checked])

    return (
        <div className={classes.root}>
            <FrameTemplate
                loginUser={loginUser}
                setLoginUser={setLoginUser}
                setIsLoading={setIsLoading}
                rangeDate={rangeDate}
                setRangeDate={setRangeDate}
                memberData={memberData}
                setMemberData={setMemberData}
                setTitle={setTitle}
                title={title}
                notificationCount={notificationCount}
                distribution={distribution}
                setDistinction={setDistinction}
                setAttendanceDay={setAttendanceDay}
                setAllAttendanceDay={setAllAttendanceDay}
                setExpanded={setExpanded}
            />
            <Contents>
                {!loginUser || !memberData || isLoading ? (
                    <BoxLoading />
                ) : (
                    <div style={{ background: "#fff", padding: "52px 0px 100px 0px" }}>
                        <Tabs
                            value={distinction}
                            onChange={handleChange}
                            indicatorColor="primary"
                            variant="fullWidth"
                            textColor="primary"
                        >
                            <Tab label="予定" className={classes.font} />
                            <Tab label="過去" className={classes.font} />
                        </Tabs>

                        {attendanceDay &&
                            attendanceDay.length > 0 &&
                            expanded &&
                            expanded.length > 0 &&
                            title == "出欠 予定" && (
                                <WeekAttendance
                                    distribution={distribution}
                                    loginUser={loginUser}
                                    memberData={memberData}
                                    attendanceDay={attendanceDay}
                                    setAttendanceDay={setAttendanceDay}
                                    setAllAttendanceDay={setAllAttendanceDay}
                                    expanded={expanded}
                                    setExpanded={setExpanded}
                                    distinction={distinction}
                                    linearAnimation={linearAnimation}
                                    setLinearAnimation={setLinearAnimation}
                                />
                            )}
                        {title == "出欠 過去" && (
                            <>
                                <div className={classes.selectPickerButtonsSwitch}>
                                    <SelectPickerButtons
                                        setRangeDate={setRangeDate}
                                        selectedPicker={selectedPicker}
                                        setSelectedPicker={setSelectedPicker}
                                    />
                                    <div className={classes.sortDisplaySwitch}></div>
                                    <SortDisplaySwitch
                                        isSortDisplay={isSortDisplay}
                                        setIsSortDisplay={setIsSortDisplay}
                                    />
                                </div>
                                <div className={classes.picker}>
                                    <SelectPicker
                                        rangeDate={rangeDate}
                                        setRangeDate={setRangeDate}
                                        selectedPicker={selectedPicker}
                                        setSelectedPicker={setSelectedPicker}
                                    />
                                </div>
                                {isSortDisplay && (
                                    <div className={classes.settings}>
                                        <GenderCheckBox
                                            checked={checked}
                                            genderCheckBoxClick={genderCheckBoxClick}
                                        />
                                        <AttendanceSort
                                            sort={sort}
                                            setSort={setSort}
                                            handleSortChange={handleSortChange}
                                        />
                                    </div>
                                )}
                                <PastAttendanceList
                                    loginUser={loginUser}
                                    pastAttendanceList={pastAttendanceList}
                                    rangeDate={rangeDate}
                                    sort={sort}
                                    selectedPicker={selectedPicker}
                                />
                            </>
                        )}
                    </div>
                )}
            </Contents>
        </div>
    )
}

const Contents = styled.div`
    height: 100vh;
    background: #fff;
`
